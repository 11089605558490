import {
  buildProps,
  definePropType,
  isNumber,
  mutable,
} from '@vuesax-alpha/utils'
import type { EmitFn } from '@vuesax-alpha/utils'
import type { ExtractPropTypes } from 'vue'
import type Sizes from './sizes.vue'

export const paginationSizesProps = buildProps({
  pageSize: {
    type: Number,
    required: true,
  },
  pageSizes: {
    type: definePropType<number[]>(Array),
    default: () => mutable([10, 20, 30, 40, 50, 100] as const),
  },
} as const)

export type PaginationSizesProps = ExtractPropTypes<typeof paginationSizesProps>

export const paginationSizesEmits = {
  'page-size-change': (val: number) => isNumber(val),
}

export type PaginationSizesEmits = typeof paginationSizesEmits

export type paginationSizesEmitsFn = EmitFn<PaginationSizesEmits>

export type SizesInstance = InstanceType<typeof Sizes>
